





























import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

import router from '@/router';

const DEFAULT_FILL = 'var(--neutralcolor-low-dark)';

@Component
export default class GoBack extends Vue {
  @Prop({ default: DEFAULT_FILL }) fill!: string;
  @Prop() link!: string;
  @Prop() nameRoute!: string;
  @Prop({ default: true }) hiddenMobile!: boolean;

  private display = 'initial';

  @Watch('$responsive.isMobile', {
    immediate: true,
  })
  hideGoBack() {
    if (this.$responsive.isMobile && this.hiddenMobile) {
      this.display = 'none';
    } else {
      this.display = 'initial';
    }
  }

  goBack() {
    if (this.nameRoute) {
      router.push({ name: this.nameRoute });
    } else {
      router.push(this.link);
    }
    this.$emit('handle-click-button');
  }
}
